import React, { useEffect, useState } from 'react';
import Navigation from './src/navigation/Navigation';
import { ModalContainer } from './src/components';
import { Store } from './src/store';
import { COLOR } from './src/const';
import { ASSETS } from './src/assets';
import './App.css';

function AppWeb() {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    Store.Client.checkSiteAndSetHostProd()
    .then((host) => {
      if (Store.Client.Site) {
        const mainColor = Store.Client.Site.theme.mainColor;
        COLOR.MAIN = mainColor;
        // if (Store.Client.Site.logo) {
        //   ASSETS.LOGO = {
        //     uri: Store.Client.Site.logo
        //   }
        // }
        ASSETS.LOGO = {
          uri: Store.Client.Site.logo || '',
        }
      }
      if (!!host) {
        setReady(true);
      }
      
    });
  }, []);

  if (!ready) return null;
  return (
    <ModalContainer>
      <Navigation />
    </ModalContainer>
  );
}

export default AppWeb;