import Editor from "./Editor";
import Request from './Request.utils';
import * as Api from './api';
import { TSite } from './type/TypeSite';

class PrintManagerClient {

  setHost(v: string) {
    Request.setHost(v);
  }
  setToken(v: string) {
    Request.setToken(v);
  }
  getToken = () => Request.getToken();

  editor = new Editor(this);

  Site: TSite | null = null

  Api = Api

  setHostSlugProd(slug: string) {
    this.setHost(`https://${slug}.api.prismpresent.com`);
  }

  async checkSiteAndSetHostProd() {
    const parts = window.location.host.split('.');
    const savedSlug = localStorage.getItem('site-slug');
    // @ts-ignore
    const isDev = process.env.NODE_ENV === 'development';
    // console.log('savedSlug', savedSlug);
    let slug;
    if (window.location.host.includes('localhost') && window.location.pathname.includes('/site/')) {
      const pathnames = window.location.pathname.split('/');
      slug = pathnames[pathnames.length - 1];
    } else if (parts.length === 4) {
      slug = parts[0];
    } else if (window.location.host === 'prismpresent.com') {
      slug = 'global';
    } else if (!!savedSlug) {
      slug = savedSlug;
    }
    localStorage.setItem('site-slug', slug as string);
    const host = !isDev ? `https://${slug}.api.prismpresent.com` : 'http://localhost:3001';
    this.setHost(host)
    const res = await Api.Site.fetchSite();
    const data = res.data;
    if (data.data) {
      this.Site = data.data;
    }
    return host;
  }
}

export * from './type';

export default new PrintManagerClient();