import React, { useState } from 'react';
import { Row, Col, Text, Input, modal, TouchField, Button } from 'components';
import { COLOR } from 'const';
import { ScrollView } from 'react-native';
import { TEbook } from 'type';
import moment from 'moment';
import Store from 'store';
// import { Entypo } from '@expo/vector-icons';
import { Fontisto } from '@expo/vector-icons';

export const SaveModal = ({ onSave, initialShouldUseAI }) => {
  const [v, setV] = useState('');
  const [shouldUseAI, setShouldUseAI] = useState(initialShouldUseAI || false);
  return (
    <Col round1 bgWhite shadow p2 width={300}>
      <Text mb1>Please describe your changes</Text>
      <Input mb1 value={v} onChange={setV} placeholder="" />

      <Row justifyContent={'space-between'}>
        <TouchField
            height={30}
            ph2
            middle
            borderColor={'transparent'}
            borderRadius={10}
            borderWidth={1}
            backgroundColor={'transparent'}
            m0
            onPress={() => {
              setShouldUseAI(!shouldUseAI);
            }}
          >
            <Row>
              <Fontisto name={shouldUseAI ? "checkbox-active" : 'checkbox-passive'} size={16} color="black" />
              <Text color={'black'} ml0>AI Assisstant</Text>
            </Row>
        </TouchField>
        <TouchField
            height={30}
            ph2
            middle
            borderColor={COLOR.MAIN}
            borderRadius={10}
            borderWidth={1}
            backgroundColor={COLOR.MAIN}
            m0
            onPress={() => {
              if (!v) return alert('Please fill in the field');
              modal.hide();
              onSave({
                message: v,
                shouldUseAI,
              });
            }}
          >
            <Row>
              <Text color={'white'}>Save</Text>
            </Row>
        </TouchField>
      </Row>
    </Col>
  )
}

export const ListRevisionModal = ({ ebook } : { ebook : TEbook }) => {

  const apply = async (item) => {
    const res = await Store.Api.Ebook.applyRevision({
      id: ebook.id,
    }, {
      revisionId: item.id,
    });
    if (res.data.success) {
      alert('Apply revision successfully');
      window.location.reload();
    }
  }

  return (
    <Col round1 bgWhite shadow p2 width={300} height={400}>
      {!ebook.revisions || !Array.isArray(ebook.revisions) || ebook.revisions.length === 0 ? (
        <Col flex1 middle>
          <Text>This ebook does not have any revision. New revision will be created every time you press Save</Text>
        </Col>
      ) : (
        <ScrollView
          style={{ height: '100%' }}
        >
          {ebook.revisions.reverse().map((v, vi) => {
            const isActive = ebook.activeRevisionId === v.id;
            return (
              <Row key={v.id} p0 paddingBottom={10} mb2 borderBottomColor={COLOR.MAIN} borderBottomWidth={1}>
                <Col flex1>
                  <Text mb0 bold={isActive}>{v.message}</Text>
                  <Text mb1 caption>{moment(v.timestamp).format('DD/MM/YYYY HH:mm:ss')}</Text>
                </Col>
                <Button
                  text="Apply"
                  outline
                  width={70} height={30} borderRadius={15} ml0
                  onPress={isActive ? undefined : () => apply(v)}
                />
              </Row>
            )
          })}
        </ScrollView>
      )}
    </Col>
  )
}