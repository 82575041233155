import React, { useEffect, useState, useRef } from 'react';
import { IScreen, TSite, TUser } from 'type';
import { CMSLayout, Col, Row, Text, Button, TouchField, Input02, Select01, RatioCol, RatioCol2, UploadFile, useUIState, useRefState, Grid, Input, modal, ManageListUser, ManageListDepartments } from 'components';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR, SCREEN } from 'const';
import Store from 'store';
import { Image } from 'react-native';
import { Entypo, AntDesign } from '@expo/vector-icons';
import { TwitterPicker } from 'react-color';
import { SVG } from 'assets';
import { EvilIcons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';

const SiteAdmin: IScreen = () => {

  const { navigation, route } = useNavFunc();
  
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const SiteStore = Store.useSiteStore();
  const [site, setSite] = useState<TSite>(Store.Client.Site);
  const [id, setId] = useState(Store.Client.Site.id);
 
  const [{ loading: submitting }, setSubmitUI] = useUIState();

  const [listUsers, setListUsers] = useState<Array<TUser>>([]);

  const uploadRef = useRef<{ showDialog: Function }>(null);

  const getSiteUsers = () => {
    Store.Api.User.listUsersBySite({ id }).then((res) => {
      if (res.data.success && res.data.data) {
        setListUsers(res.data.data);
      }
    });
  }

  useEffect(() => {
    if (id === 'new') return;
    getSiteUsers();
  }, [id]);

  const onChangeField = (label) => (newValue) => {
    setSite({
      ...site,
      [label]: newValue,
    })
  }

  const submit = async (s = site) => {
    setSubmitUI({ loading: true });
    try {
      const res = await Store.Api.Site.upsert({
        id: id === 'new' ? undefined : id,
        name: s?.name,
        logo: s?.logo,
        slug: s?.slug,
        theme: {
          ...s?.theme,
          mainColor: s?.theme?.mainColor || 'black'
        },
        settings: {
          ...s?.settings,
        },
        departments: s?.departments || [],
      });
      if (res.data.error) {
        alert(res.data.error);
      } else if (res.data.data) {
        alert('Successfully submitted');
        navigation.reset({
          index: 0,
          routes: [{ name: SCREEN.UpsertSite, params: { id: res.data.data.id } }],
        });
      }
    } catch (err) {
      alert(String(err));
    }
    setSubmitUI({ loading: false });
  }

  const onImageUploaded = urls => {
    console.log('urls', urls);
    if (!!urls && !!urls[0]) {
      setSite({
        ...site,
        logo: urls[0],
      })
    }
  }


  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3>{id === 'new' ? 'Create new site' : `${site?.name}`}</Text>
        <Row>
          <Button
            isLoading={submitting}
            text={id === 'new' ? "Create" : "Save"}
            width={100} height={40} borderRadius={20}
            onPress={() => submit(site)}
          />
        </Row>
      </Row>
      <Col flex1 m2 mv1 p1 round1 bgWhite overflow={'scroll'}>
        <Grid xs='100%' md='2:3' alignItems={'flex-start'} mb2>
          <Col m1>
            <Text subtitle1 mb2>Site information</Text>
            <Row alignItems={'flex-start'}>
              <Col mr1>
                <Row justifyContent={'space-between'}>
                  <Text mb0>Logo</Text>
                </Row>
                <Col width={120} height={120} backgroundColor={COLOR.GREY_LIGHT} mb1>
                  {Boolean(!!site?.logo) && (
                    <Col flex1 p1>
                      <Image source={{ uri: site?.logo }} style={{ width: '100%', height: '100%' }} resizeMode='contain' />
                    </Col>
                  )}
                  <Col absolute top={0} right={0}>
                    <TouchField cirle middle
                      onPress={() => {
                        uploadRef?.current && uploadRef.current.showDialog();
                      }}
                    >
                      <Entypo name="upload" size={15} color="black" />
                    </TouchField>
                  </Col>
                  <UploadFile
                    ref={uploadRef}
                    onUploaded={onImageUploaded}
                  />
                </Col>
              </Col>
              <Col flex1>
                <Text mb0>Name</Text>
                <Input
                  value={site?.name || ''}
                  mb1
                  onChange={onChangeField('name')}
                />
              </Col>
            </Row>


            <Row mb2>
              {Boolean(site?.theme?.mainColor) && (
                <Col width={15} height={15} backgroundColor={site?.theme?.mainColor} mr1>
                </Col>
              )}
              <Text >Main Color</Text>
            </Row>
            <TwitterPicker
              color={site?.theme?.mainColor || 'black'}
              onChange={(color) => {
                setSite({
                  ...site,
                  theme: {
                    ...site.theme,
                    mainColor: color.hex,
                  }
                });
              }}
            />
          </Col>
          <Col m1>
            {id === 'new' ? null : (
              <ManageListUser site={site} />
            )}
            {id === 'new' ? null : (
              <ManageListDepartments site={site} setSite={setSite} submit={submit} />
            )}
          </Col>
        </Grid>
        <Col p1>

        </Col>
      </Col>
    </CMSLayout>
  );
};

SiteAdmin.routeInfo = {
  title: 'Site management',
  path: '/site-management',
};

export default SiteAdmin;
