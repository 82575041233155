import { useEffect } from 'react';
import Client from './api-client';
import { useUserStore } from './User.Store';
import { usePrintJobStore } from './PrintJob.Store';
import { useDesignStore } from './Design.Store';
import { useSiteStore } from './Site.Store';

// default to 'http://localhost:3000'
Client.setHost('http://localhost:3001');

if (process.env.NODE_ENV !== 'development') {
  Client.checkSiteAndSetHostProd();
}

export const Store = {
  Client,
  Api: Client.Api,
  useUserStore,
  usePrintJobStore,
  useDesignStore,
  useSiteStore,
}

export default Store;