import React, { useEffect, useRef, useState } from "react";
import { Image, View } from "react-native";
import { Video, ResizeMode } from "expo-av";

const VideoShowing = ({url, ...props}) => {
  const video = React.useRef(null);
  return (
    <View>
      <Video
        ref={video}
        style={{ width: "100%"}}
        videoStyle={{ width: "100%", position: 'relative' }}
        source={{
          uri: url,
        }}
        useNativeControls
        resizeMode={ResizeMode.CONTAIN}
        isLooping
      />
    </View>

  )
};

export default VideoShowing;
