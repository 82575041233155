export const SCREEN = {
  Login: 'Login',
  Home: 'Home',
  SampleEditor: 'SampleEditor',
  SampleEditorTheChild: 'SampleEditorTheChild',
  ListPrintJobs: 'ListPrintJobs',
  Reports: 'Reports',
  PrintHistory: 'PrintHistory',
  ListProducts: 'ListProducts',
  UpsertProduct: 'UpsertProduct',
  ListDesigns: 'ListDesigns',
  UpsertDesign: 'UpsertDesign',
  ListResellers: 'ListResellers',
  UpsertReseller: 'UpsertReseller',
  ListInstances: 'ListInstances',
  UpsertInstance: 'UpsertInstance',
  ListStores: 'ListStores',
  UpsertStore: 'UpsertStore',
  HPData: 'HPData',
  ListSites: 'ListSites',
  UpsertSite: 'UpsertSite',
  ListEbooks: 'ListEbooks',
  EditEbook: 'EditEbook',
  NewEbook: 'NewEbook',
  EbookAnalytic: 'EbookAnalytic',
  SiteAdmin: 'SiteAdmin',
};
