import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Text, Input, TouchField, Row, modal, useUIState, Button, Grid, Select02 } from 'components';
import { COLOR } from 'const';
import Store from 'store';
import { ActivityIndicator, useWindowDimensions } from 'react-native';
import { TimeHelper } from 'helpers';
import { TGeneralData } from 'type';

interface IProps {
  userId: string,
  siteId: string,
  onSuccess: Function,
  departments: Array<{ title: string, id: string }>,
}

const UpsertUserModal = ({ userId, siteId, onSuccess, departments }: IProps) => {
  const { width } = useWindowDimensions();
  const dataId = useRef(null);
  const [showPasswordFields, setShowPasswordFields] = useState(userId === 'new');
  const [ebooks, setEbooks] = useState([]);
  const [assignedEbooks, setAssignedEbooks] = useState([]);
  const [assignedDepartments, setAssignedDepartments] = useState([]);
  const [role, setRole] = useState<{ label: string, value: string }>();
  const [{ loading, errorMes, fetching }, setUI] = useUIState();

  const UserStore = Store.useUserStore();
  const { user, setUser } = UserStore.useUser(userId);

  const [confirmPass, setConfirmPass] = useState('');

  const onChangeField = (label) => (newValue) => {
    setUser({
      ...user,
      [label]: newValue,
    })
  }

  useEffect(() => {
    if (user?.role) {
      // @ts-ignore
      setRole(roleOptions.find((r) => r.value === user.role));
    }
  }, [user]);

  useEffect(() => {
    if (user?.otherData?.ebooks) {
      const listAssigned = [];
      user.otherData.ebooks.forEach((id) => {
        const find = ebooks.find((v) => v.id === id);
        if (find) {
          listAssigned.push({
            label: find.name,
            value: find.id,
          });
        }
      });
      setAssignedEbooks(listAssigned);
    }
  }, [user, ebooks]);

  useEffect(() => {
    if (user?.otherData?.departments) {
      const listAssigned = [];
      user.otherData.departments.forEach((id) => {
        const find = departments.find((v) => v.id === id);
        if (find) {
          listAssigned.push({
            label: find.title,
            value: find.id,
          });
        }
      });
      setAssignedDepartments(listAssigned);
    }
  }, [user, departments]);

  useEffect(() => {
    (async () => {
      const res = await Store.Api.Ebook.list({ page: 1, withoutRevisions: 1, siteId });
      const { list, hasNext, total, error } = res.data.data;
      if (error) return alert(error);
      setEbooks(list);
    })();
  }, [siteId]);

  const save = async () => {
    if (!confirmPass && user.password) {
      return alert('Confirm password not match ');
    } else if (!!confirmPass && confirmPass != user.password) {
      return alert('Confirm password not match ');
    }
    if (
      !user.firstName
      || !user.lastName
      || !user.email
    ) return alert('Please fill in all the fields');
    if (!role || !role.value) {
      return alert('Please assign a role to the user');
    }
    setUI({ loading: true });
    await TimeHelper.wait(300);
    const res = await Store.Api.User.upsert({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: user.password || undefined,
      role: role.value,
      otherData: {
        ...user?.otherData,
        ebooks: assignedEbooks.map((v) => v.value),
        departments: assignedDepartments.map((v) => v.value),
      },
      siteId,
    });
    if (res.data.error) {
      setUI({ loading: false });
      return alert(res.data.error);
    }
    setUI({ loading: false });
    onSuccess();
    modal.hide();
  }
  const shouldShowGlobalClient = siteId === '1';
  const roleOptions = [
    shouldShowGlobalClient ? {
      label: 'Global Admin - can manage everything',
      value: 'admin'
    } : null,
    {
      label: 'Admin - can manage all ebooks within the site',
      value: 'client-admin'
    },
    {
      label: 'User - can manage specific ebooks',
      value: 'user'
    },
  ].filter(Boolean);

  const ebookOptions = ebooks.map(v => ({
    label: v.name,
    value: v.id,
  }));

  const departmentOptions = departments.map(v => ({
    label: v.title,
    value: v.id,
  }));

  return (
    <Col round1 bgWhite shadow p2 width={width <= 600 ? width - 30 : width * 0.7}>
      <Text fontSize={16} m1 bold>{userId === 'new' ? 'New User' : 'Update user'}</Text>
      <Grid xs='100%' md='1:.' alignItems={'flex-start'}>
        <Col margin={10}>
          <Col flex1>
            <Text mb0>First Name</Text>
            <Input
              value={user?.firstName || ''}
              mb1
              onChange={onChangeField('firstName')}
            />
            <Text mb0>Last Name</Text>
            <Input
              value={user?.lastName || ''}
              mb1
              onChange={onChangeField('lastName')}
            />
            <Text mb0>Email</Text>
            <Input
              value={user?.email || ''}
              mb1
              onChange={onChangeField('email')}
            />
            {showPasswordFields ? (
              <>
                <Text mb0>Password</Text>
                <Input
                  value={user?.password || ''}
                  mb1
                  password
                  onChange={onChangeField('password')}
                />
                <Text mb0>Confirm Password</Text>
                <Input
                  value={confirmPass}
                  mb2
                  password
                  onChange={(v) => setConfirmPass(v)}
                />
              </>
            ) : (
              <>
                <Button mb2 outline text='Change password' onPress={() => setShowPasswordFields(true)} />
              </>
            )}
            <Button
              isLoading={loading}
              width='100%'
              mb1
              height={45}
              text={userId === 'new' ? 'Create' : "Save"}
              onPress={save}
            />
          </Col>
        </Col>
        <Col margin={10}>
          <Text mb0>User role</Text>
          <Select02
            options={roleOptions}
            value={role}
            onChange={setRole}
            placeholder='Select role'
            mb2
          />
          {role?.value === 'user' && (
            <Col mb2>
              <Text mb0>Assigned ebooks</Text>
              <Select02
                options={ebookOptions}
                value={assignedEbooks}
                onChange={setAssignedEbooks}
                placeholder='Select ebooks'
                isMulti
              />
            </Col>
          )}
          {role?.value === 'user' && (
            <Col>
              <Text mb0>Assigned departments</Text>
              <Select02
                options={departmentOptions}
                value={assignedDepartments}
                onChange={setAssignedDepartments}
                placeholder='Select departments'
                isMulti
              />
            </Col>
          )}
        </Col>
      </Grid>


    </Col>
  );
};

export default UpsertUserModal;