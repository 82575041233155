import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Text, Input, TouchField, Row, modal, useUIState, Button, Grid, Select02 } from 'components';
import { COLOR } from 'const';
import Store from 'store';
import { ActivityIndicator, useWindowDimensions } from 'react-native';
import { TimeHelper } from 'helpers';
import { TGeneralData, TSite } from 'type';

interface IProps {
  site: TSite,
  setSite: Function,
  onSuccess: Function,
  department: {
    id: string,
    title: string,
  },
}

const UpsertDepartmentModal = ({ site, setSite, onSuccess, department }: IProps) => {
  const [{ loading, errorMes, fetching }, setUI] = useUIState();
  const [departmentTitle, setDepartmentTitle] = useState(department.title || '');

  const save = async () => {
    const departments = (site.departments || []).slice();
    const findExisted = departments.find(v => v.title === departmentTitle);
    if (findExisted) {
      alert(`Error: The department: ${departmentTitle} has already been added!`);
      return;
    }
    if (department.id === 'new') {
      departments.push({
        id: new Date().getTime().toString(),
        title: departmentTitle,
      })
    }
    const findIndex = departments.findIndex((d) => d.id === department.id);
    if (findIndex > -1) {
      departments[findIndex] = {
        ...departments[findIndex],
        title: departmentTitle,
      }
    }
    const newSite = {
      ...site,
      departments,
    }
    setSite(newSite);
    modal.hide();
    onSuccess(newSite);
  }

  return (
    <Col round1 bgWhite shadow p2 width={300}>
      <Text fontSize={16} m1 bold>{department.id === 'new' ? 'New Department' : 'Update department: ' + departmentTitle}</Text>
      <Grid xs='100%' md='1:.' alignItems={'flex-start'}>
        <Col margin={10}>
          <Col flex1>
            <Text mb0>Title</Text>
            <Input
              value={departmentTitle}
              mb1
              onChange={setDepartmentTitle}
            />
            <Button
              isLoading={loading}
              width='100%'
              mb1
              height={45}
              text={department.id === 'new' ? 'Create' : "Save"}
              onPress={save}
            />
          </Col>
        </Col>
      </Grid>


    </Col>
  );
};

export default UpsertDepartmentModal;