import React, { useEffect, useState, useRef } from 'react';
import { IScreen, TSite, TUser } from 'type';
import { CMSLayout, Col, Row, Text, Button, TouchField, Input02, Select01, RatioCol, RatioCol2, UploadFile, useUIState, useRefState, Grid, Input, modal } from 'components';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR, SCREEN } from 'const';
import Store from 'store';
import { Image } from 'react-native';
import { Entypo, AntDesign } from '@expo/vector-icons';
import { TwitterPicker } from 'react-color';
import { SVG } from 'assets';
import UpsertUserModal from './UpsertSiteUser.Modal';
import { EvilIcons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';

const ManageListUser = ({ site }: { site: TSite }) => {

  const [listUsers, setListUsers] = useState<Array<TUser>>([]);

  const getSiteUsers = () => {
    Store.Api.User.listUsersBySite({ id: site.id }).then((res) => {
      if (res.data.success && res.data.data) {
        setListUsers(res.data.data);
      }
    });
  }

  useEffect(() => {
    if (!!site?.id && site?.id !== 'new') {
      getSiteUsers();
    }
  }, [site]);

  const renderRole = user => {
    switch(user?.role) {
      case 'admin': return 'Global Admin';
      case 'client-admin': return 'Site Admin';
      case 'user': return 'Site User';
      default: return '';
    }
  }

  const showUserModal = (userId: string) => {
    modal.show(
      <UpsertUserModal
        userId={userId}
        siteId={site.id}
        onSuccess={() => {
          getSiteUsers();
        }}
        departments={site?.departments || []}
      />
    )
  }

  const deleteUser = async (user: TUser) => {
    const shouldDelete = confirm('Are you sure to delete this user: ' + [user.firstName, user.lastName].join(' '));
    if (!shouldDelete) return;
    const res = await Store.Api.User.deleteUser({
      id: user.id,
    });
    if (res.data.success) {
      window.location.reload();
    }
  }

  return (
    <Col>
      <Row mb2>
        <Text subtitle1 mr1>Site Users</Text>
        <TouchField cirle middle onPress={() => showUserModal('new')}>
          <AntDesign name="pluscircleo" size={20} color={COLOR.MAIN} />
        </TouchField>
      </Row>
      {listUsers.map((u, uI) => {
        return (
          <Row key={u.id} mb1 justifyContent={'space-between'} alignItems={'flex-start'}>
            <Col flex1>
              <Text>{u.firstName || ''} {u.lastName || ''}</Text>
              <Text caption mt0 color={'rgba(0,0,0,0.2)'}>{renderRole(u)}</Text>
            </Col>
            <Col flex1>
              <Text bold>{u.email}</Text>
            </Col>
            <Row width={150} ml1 justifyContent={'flex-end'}>
              <TouchField cirle middle onPress={() => deleteUser(u)}>
                <FontAwesome5 name="trash-alt" size={16} color="rgba(0,0,0,0.5)" />
              </TouchField>
              <TouchField cirle middle onPress={() => showUserModal(u.id)}>
                <SVG.Pencil width={15} height={15} />
              </TouchField>
            </Row>
          </Row>
        )
      })}
    </Col>
  );
};

export default ManageListUser;
