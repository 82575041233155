import React, { useRef } from 'react';
import { Col, TouchField } from '../../index';
import UploadFile from './UploadFile';
import { Entypo } from '@expo/vector-icons';

const ImageUploadChange = ({ onChange, imageUrl, renderImage, ...props } : any) => {

  const uploadRef = useRef<{ showDialog: Function }>(null);

  const onImageUploaded = (urls) => {
    onChange(urls[0]);
  };

  return (
    <Col {...props}>
      {renderImage(imageUrl)}
      <UploadFile
        ref={uploadRef}
        onUploaded={onImageUploaded}
      />
      <TouchField
        absolute
        top={0}
        right={0}
        backgroundColor={"rgba(255,255,255,0.5)"}
        cirle middle
        onPress={() => {
          uploadRef?.current && uploadRef.current.showDialog();
        }}
      >
        <Entypo name="upload" size={15} color="black" />
      </TouchField>
    </Col>
  );
};

export default ImageUploadChange;
