import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Text, SelectLanguage } from 'components';
import { TEbook } from 'type';
import Store from 'store';

const EbookLanguage = ({ currentLang, ebook, onChangeLang }: { currentLang: string, ebook: TEbook, onChangeLang: Function }) => {
  const [value, setValue] = useState<{ value: string, label: string }>({ value: '', label: 'Default language' });

  const fullLangOptions = SelectLanguage.fullLangOptions;
  const ebookLanguageOptions = useMemo(() => {
    const arr = [
      {
        label: 'Ebook languages',
        options: [],
      },
      {
        label: 'Add new language',
        options: [],
      }
    ];
    fullLangOptions.forEach(lang => {
      const find = (ebook?.languages || []).find(v => v.code === lang.value);
      if (find) arr[0].options.push({
        label: find.language,
        value: find.code,
      });
      else arr[1].options.push(lang);
    })
    return arr;
  }, [ebook]);

  useEffect(() => {
    if (!currentLang) return;
    const find = ebookLanguageOptions[0].options.find(v => v.value === currentLang);
    if (find) setValue(find);
  }, [currentLang, ebookLanguageOptions]);

  const handleChangeLanguage = async (data) => {
    console.log('handleChangeLanguage', data);
    if (!data) return;
    const previousValue = { ...value };
    setValue(data);
    const langCode = data.value;
    const findIndex = (ebook?.languages || []).findIndex(v => v.code === langCode);
    if (findIndex === -1) {
      const shouldContinue = confirm('The ebook will be cloned with the exact same content, and it will need to be manually translated into the language. Please confirm by clicking OK, or abort by clicking Cancel');
      if (!shouldContinue) return setValue(previousValue);
      const res = await Store.Api.Ebook.addLanguage({
        id: ebook?.id,
      }, {
        language: data.label,
        code: data.value,
      });
      if (res.data.success) {
        onChangeLang(res.data.data.code)
      } else {
        alert(res.data.error);
      }
    } else {
      await Store.Api.Ebook.addLanguage({
        id: ebook?.id,
      }, {
        language: data.label,
        code: data.value,
      });
      onChangeLang(langCode);
    }
  }

  return (
    <Row width={250} justifyContent={'flex-end'}>
      <Col flex1>
        <SelectLanguage
          value={value}
          onChange={handleChangeLanguage}
          options={ebookLanguageOptions}
          placeholder='Ebook languages..'
          innerProps={{
            noOptionsMessage: () => `No language.. Type to add`,
          }}
        />
      </Col>
    </Row>
  )
};

export default EbookLanguage;