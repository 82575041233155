import { Col, Row, Text, CMSLayout, useUIState, ShimmerLoading, TouchField, Button, PrintJobItem, SwapIconEffect, modal, EbookCloneModal } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen, TEbook, TPrintJob, TSite, TUser } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator, Image } from 'react-native';
import moment from 'moment';
import { SVG } from 'assets';
import { SimpleLineIcons, Foundation } from '@expo/vector-icons';
import { saveAs } from 'file-saver';
import { TimeHelper } from 'helpers';
import { Entypo, Feather, Ionicons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';

const ListEbooks: IScreen = () => {
  const { navigation } = useNavFunc();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const SiteStore = Store.useSiteStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [ebooks, setEbooks] = useState<Array<TEbook>>([]);
  const [sites, setSites] = useState<{ [siteId: string]: TSite }>({});
  const [deletingId, setDeletingId] = useState<{ [id: string]: boolean }>({})

  console.log('user', user);

  const getData = async () => {
    try {
      setUI({ fetching: true, errorMes: '' });
      await TimeHelper.wait(300);
      console.log(
        user?.role,
        user?.role === 'admin' && Store.Client.Site.id === '1' ? '' : Store.Client.Site.id
      )
      const res = await Store.Api.Ebook.list({
        page: 1,
        withoutRevisions: 1,
        siteId: user?.role === 'admin' && Store.Client.Site.id === '1' ? '' : Store.Client.Site.id,
      });
      const { list, hasNext, total, error } = res.data.data;
      if (error) return setUI({ fetching: true, errorMes: error });
      setPagination({ hasNext, total });
      setEbooks(list);
      setUI({ fetching: false, errorMes: '' });
    } catch (err) {
      setUI({ fetching: true, errorMes: String(err) });
    }
  };

  const getSites = async () => {
    try {
      
      const { list, hasNext, total, error } = await SiteStore.getList();
      if (list && list.length > 0) {
        const siteObj = {};
        list.forEach((s) => {
          siteObj[s.id] = s;
        })
        console.log('siteObj', siteObj);
        // @ts-ignore
        setSites(siteObj);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (user?.role === 'admin') {
      getSites();
    }
  }, [user])

  useEffect(() => {
    if (!user) return;
    // will getData when screen is focused
    getData();
    const unsubscribe = navigation.addListener('focus', () => {
      getData();
    });
    return unsubscribe;
  }, [user]);

  const genEbookUrl = (e: TEbook) => {
    const site = sites[e.siteId];
    if (!site) return null;
    const productionHost = () => {
      const slug = site.slug;
      if (slug === 'worldpay') return 'https://ebook.worldpaytravel.com/e';
      return slug === 'global' ? 'https://ebooks.prismpresent.com/e' : `https://${slug}.v2.prismpresent.com/e`;
    };
    const genUrl = (langCode: string) => {
      const serverPath = e?.tempServerPath + ( !!langCode && langCode !== 'undefined' ? langCode + '.html' : '' );
      return (productionHost()) + serverPath
    };
    const arr = [
      '',
      ...(e.languages || []).map(v => v.code),
    ];
    return arr.map((v, vI) => {
      const url = genUrl(v);
      return (
        <a href={url} target='_blank' style={{ textDecoration: 'none', display: 'inline-block', margin: 5 }}>
          {v === '' ? (
            <FontAwesome name="globe" size={20} color={COLOR.MAIN} /> 
          ) : (
            <span style={{ fontSize: 12, padding: 4, display: 'inline-block', backgroundColor: COLOR.MAIN, margin: 5, color: 'white', borderRadius: 4 }}>
              {v.toUpperCase()}
            </span>
          )}
        </a>
      )
    }) 
  }

  const showCloneEbookModal = (ebook : TEbook) => {
    modal.show(
      <EbookCloneModal
        ebook={ebook}
      />
    )
  }

  const canClone = user?.role === 'admin' || user?.role === 'client-admin';
  const canDelete = user?.role === 'admin' || user?.role === 'client-admin';

  const renderJobList = () => {
    if (ebooks.length === 0) {
      return (
        <Col flex1>
          <Text>No ebook found</Text>
        </Col>
      )
    }
    return (
      <Col flex1>
        <Row
          height={50} stretch
          borderBottomColor={COLOR.GREY_BG}
          borderBottomWidth={1}
        >
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Name</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>URL</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Created At</Text>
          </Col>
          <Col width={100} m1 />
        </Row>
        <Col>
          {ebooks.map((s, sI) => {
            const subInfo = [
              user?.role === 'admin' && sites[s.siteId]?.name ? sites[s.siteId]?.name + '' : '',
              s.departmentTitle
            ].filter(Boolean).join(' / ');
            return (
              <Row key={s.id} height={50}>
                <Col flex1 m1>
                  <Text>{s.name}</Text>
                  <Text color={COLOR.GREY} caption>
                    {subInfo}
                  </Text>
                </Col>
                <Row flex1 m1 flexWrap='wrap'>
                  {genEbookUrl(s)}
                </Row >
                <Col flex1 m1>
                  <Text>{TimeHelper.formatDay(s.createdAt)}</Text>
                </Col>
                <Row width={100} m1 >
                  {canClone && (
                    <TouchField cirle middle mr1
                      onPress={() => showCloneEbookModal(s)}
                    >
                      <FontAwesome name="clone" size={16} color="black" />
                    </TouchField>
                  )}
                  {canDelete && (
                    <TouchField cirle middle
                      onPress={async () => {
                        const shouldDelete = confirm(`Are you sure to delete this ebook: ${s.name}?`);
                        if (!shouldDelete) return;
                        setDeletingId({
                          ...deletingId,
                          [s.id]: true,
                        })
                        const res = await Store.Api.Ebook.deleteEbook({ id: s.id });
                        setDeletingId({
                          ...deletingId,
                          [s.id]: false,
                        })
                        if (res.data.success) {
                          getData();
                        }
                      }}
                    >
                      {deletingId[s.id] ? <ActivityIndicator size="small" color="black" /> : (
                        <Feather name="trash-2" size={16} color="black" />
                      )}
                    </TouchField>
                  )}

                  <TouchField cirle middle
                    onPress={() => {
                      navigation.reset({
                        index: 0,
                        routes: [{name: SCREEN.EditEbook, params: { id: s.id }}],
                      });
                    }}
                  >
                    <SVG.Pencil width={20} height={20} />
                  </TouchField>
                </Row>
              </Row>
            )
          })}
        </Col>
      </Col>
    )
  }

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3 >List ebooks</Text>
        <Button
          text="Add new ebook"
          width={200} height={40} borderRadius={20}
          onPress={async () => {
            navigation.reset({
              index: 0,
              routes: [{name: SCREEN.NewEbook}],
            });
          }}
        />
      </Row>
      <Col flex1 m2 mv1 p2 round1 bgWhite>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : (
          fetching ? (
            <Col>
              <Row height={50} stretch mt2>
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
              </Row>
              <Row height={50} stretch mt2>
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
              </Row>
            </Col>
          ) : (
            renderJobList()
          )
        )}
      </Col>
    </CMSLayout>
  )
};

ListEbooks.routeInfo = {
  title: 'List Ebooks',
  path: '/ebooks',
};

export default ListEbooks;