import { Col, Row, Text, CMSLayout, useUIState, ShimmerLoading, TouchField, Button, PrintJobItem, SwapIconEffect, Grid, RatioCol, ImageUploadChange, modal } from 'components';
import React, { useState, useEffect, useRef } from 'react';
import { IScreen, TEbook, TPrintJob, TSite, TUser } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator, Image, ScrollView } from 'react-native';
import moment from 'moment';
import { SVG } from 'assets';
import { SimpleLineIcons, Foundation } from '@expo/vector-icons';
import { saveAs } from 'file-saver';
import { TimeHelper } from 'helpers';
import { Entypo, Feather, Ionicons } from '@expo/vector-icons';
import { set } from 'react-native-reanimated';

const EbookAnalytic: IScreen = () => {
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { id } = route.params || {};
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: true });
  const [ebookData, setEbookData] = useState<TEbook>();

  const getData = async () => {
    try {
      setUI({ fetching: true, errorMes: '' });
      await TimeHelper.wait(300);
      const res = await Store.Api.Ebook.detail({ id }, { analyticHealhCheck: 1 });
      const { data } = res.data;
      setEbookData(data);
      setUI({ fetching: false, errorMes: '' });
    } catch (err) {
      setUI({ fetching: true, errorMes: String(err) });
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  const productionHost = () => {
    if (ebookData?.url) {
      const domain = new URL(ebookData.url).hostname;
      return `https://${domain}/e`;
    }
    const slug = window.location.host.split('.')[0];
    if (slug === 'worldpay') return 'https://ebook.worldpaytravel.com/e';
    return slug === 'global' ? 'https://ebooks.prismpresent.com/e' : `https://${slug}.v2.prismpresent.com/e`;
  };
  const langCode = '';
  const serverPath = ebookData?.tempServerPath + ( !!langCode && langCode !== 'undefined' ? langCode + '.html' : '' );
  const editUrl = (process.env.NODE_ENV !== 'development' ? productionHost() : 'http://localhost:3001/e') + serverPath;

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Row>
          <Text h3>Analytics {ebookData?.name ? ': ' + ebookData?.name : ''}</Text>
          <Button
            text="View Ebook"
            outline
            ml1
            width={100} height={30} borderRadius={20}
            onPress={async () => {
              window.open(editUrl, '_blank');
            }}
          />
        </Row>
        <Row>
          <Button
            text="Go back"
            outline
            width={100} height={30} borderRadius={20} mr1
            onPress={async () => {
              navigation.navigate(SCREEN.EditEbook, { id });
            }}
          />
        </Row>
      </Row>
      <Col flex1 p2 overflow='auto'>
        {ebookData?.analyticEmbedUrl ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `
                <iframe plausible-embed src="${ebookData?.analyticEmbedUrl}" scrolling="no" frameborder="0" loading="lazy" style="width: 1px; min-width: 100%; height: 2000px;"></iframe>
                <script async src="https://plausible.io/js/embed.host.js"></script>
              `
            }}
          />
        ) : fetching ? (
          <Col middle flex1>
            <ActivityIndicator />
            <Text mt1>Loading...</Text>
          </Col>
        ) : (
          <Col>
            <Text>This ebook has not been setup with analytics</Text>
            <Button
              text="Setup Analytics"
              width={200} height={40} borderRadius={20} mt2
              isLoading={btnLoading}
              onPress={async () => {
                setUI({ loading: true });
                try {
                  const res = await Store.Api.Ebook.addAnalytics({ id }, {});
                  if (res.data.success) {
                    alert('Analytics setup successfully');
                    window.location.reload();
                  }
                } catch(err) {}
                setUI({ loading: false });
              }}
            />
            <Text mb0 mt2>When analytic is setup, we will be able to track the following by default:</Text>
            {[
              'Page View',
              'MenuBar Click',
              'Cta Links Click',
              'Button Click',
              'Text Selected',
              'Title Selected',
            ].map((v, vI) => {
              return (
                <Row key={vI} mb0>
                  <Entypo name="dot-single" size={24} color={COLOR.MAIN} />
                  <Text ml1>{v}</Text>
                </Row>
              );
            })}
          </Col>
        )}
      </Col>
    </CMSLayout>
  )
}

EbookAnalytic.routeInfo = {
  title: 'Ebook Analytics',
  path: '/ebooks/:id/analytics',
};

export default EbookAnalytic;