import React, { useEffect, useState, useRef } from 'react';
import { IScreen, TSite, TUser } from 'type';
import { CMSLayout, Col, Row, Text, Button, TouchField, Input02, Select01, RatioCol, RatioCol2, UploadFile, useUIState, useRefState, Grid, Input, modal } from 'components';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR, SCREEN } from 'const';
import Store from 'store';
import { Image } from 'react-native';
import { Entypo, AntDesign } from '@expo/vector-icons';
import { TwitterPicker } from 'react-color';
import { SVG } from 'assets';
import { EvilIcons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import UpsertDepartmentModal from './UpsertSiteDepartment.Modal';

const ManageListDepartments = ({ site, setSite, submit }: { site: TSite, setSite: any, submit: Function }) => {

  const [listUsers, setListUsers] = useState<Array<TUser>>([]);

  const getSiteUsers = () => {
    Store.Api.User.listUsersBySite({ id: site.id }).then((res) => {
      if (res.data.success && res.data.data) {
        setListUsers(res.data.data);
      }
    });
  }

  useEffect(() => {
    if (!!site?.id && site?.id !== 'new') {
      getSiteUsers();
    }
  }, [site]);

  const showDepartmentModal = (department: { title: string, id: string }) => {
    modal.show(
      <UpsertDepartmentModal
        site={site}
        setSite={setSite}
        onSuccess={(newSite) => {
          submit(newSite, true);
        }}
        department={department}
      />
    )
  }

  const deleteDepartment = (department: { title: string, id: string }) => {
    const shouldDelete = confirm('Are you sure to delete this department: ' + department.title);
    if (!shouldDelete) return;
    const departments = (site.departments || []).filter(v => v.id !== department.id);
    setSite({
      ...site,
      departments
    });
    submit({
      ...site,
      departments
    }, true);
  }

  return (
    <Col>
      <Col mv2 height={1} backgroundColor={COLOR.BORDER} />
      <Row mb2>
        <Text subtitle1 mr1>Site Departments</Text>
        <TouchField cirle middle onPress={() => showDepartmentModal({
          id: 'new', title: ''
        })}>
          <AntDesign name="pluscircleo" size={20} color={COLOR.MAIN} />
        </TouchField>
      </Row>
      {(site?.departments || []).map((d, dI) => {
        return (
          <Row key={d.id} mb1 justifyContent={'space-between'}>
            <Text>{d.title}</Text>
            <Row>
              <TouchField cirle middle onPress={() => deleteDepartment(d)}>
                <FontAwesome5 name="trash-alt" size={16} color="rgba(0,0,0,0.5)" />
              </TouchField>
              <TouchField cirle middle onPress={() => showDepartmentModal(d)}>
                <SVG.Pencil width={15} height={15} />
              </TouchField>
            </Row>
          </Row>
        )
      })}
    </Col>
  );
};

export default ManageListDepartments;
