import { SCREEN } from 'const';

export const adminNav = [
  { title: 'List Sites', screen: SCREEN.ListSites, role: ['admin'] },
  { title: 'Site management', screen: SCREEN.SiteAdmin, role: ['client-admin'] },
  { title: 'List Ebooks', screen: SCREEN.ListEbooks },
];

export const clientNav = [
  { title: 'Site management', screen: SCREEN.SiteAdmin, role: ['client-admin', 'admin'] },
  { title: 'List Ebooks', screen: SCREEN.ListEbooks },
];