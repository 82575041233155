import Request from '../Request.utils'
export interface ILoginRequestBody {
  email: string;
  password: string;
}
export interface IRegisterRequestBody {
  __key?: string;
  email: string;
  firstName: string;
  lastName: string;
  otherData?: any;
  password: string;
  photoUrl?: string;
  role?: string;
}
export interface IGetUploadPresignedUrlRequestBody {
  contentType: string;
  serverSidePath: string;
}
export interface IListUsersBySiteRequestParams {
  id: string;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IUpsertRequestBody {
  email?: string;
  firstName?: string;
  id?: string;
  lastName?: string;
  otherData?: any;
  password?: string;
  role?: string;
  siteId: string;
}
export interface IDeleteUserRequestParams {
  id: string;
}


class UserAPI {
  login = async (body: ILoginRequestBody) => {
    const res = await Request.call('/api/users/login', 'POST', undefined, undefined, body, );
    if (res.data.data && res.data.data.token) Request.setToken(res.data.data.token)
    return res;
  }
  logout = async () => {
    const res = await Request.call('/users/logout', 'POST', undefined, undefined, undefined, );
    Request.setToken('')
    return res;
  }
  register = async (body: IRegisterRequestBody) => {
    const res = await Request.call('/api/users/register', 'POST', undefined, undefined, body, );
    return res;
  }
  me = async () => {
    const res = await Request.call('/api/users/me', 'GET', undefined, undefined, undefined, );
    return res;
  }
  getUploadPresignedUrl = async (body: IGetUploadPresignedUrlRequestBody) => {
    const res = await Request.call('/api/users/me/get-upload-url', 'POST', undefined, undefined, body, );
    return res;
  }
  listUsersBySite = async (params: IListUsersBySiteRequestParams) => {
    const res = await Request.call('/api/users/list-by-site/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/users/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/users/upsert', 'POST', undefined, undefined, body, );
    return res;
  }
  deleteUser = async (params: IDeleteUserRequestParams) => {
    const res = await Request.call('/api/users/:id', 'DELETE', params, undefined, undefined, );
    return res;
  }
}
export default new UserAPI()