import React, { useRef, useState } from 'react';
import { Row, Col, Text, Input, Button } from 'components';
import { ActivityIndicator } from 'react-native';
import { TEbook } from 'type';
import Store from 'store';

const EbookCloneModal = ({ ebook } : { ebook: TEbook }) => {
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tempServerPath, setTempServerPath] = useState('');

  const shoudAutoFillTempPath = useRef(true)

  const productionHost = () => {
    const slug = window.location.host.split('.')[0];
    if (slug === 'worldpay') return 'https://ebook.worldpaytravel.com/e';
    return slug === 'global' ? 'https://ebooks.prismpresent.com/e' : `https://${slug}.v2.prismpresent.com/e`;
  };

  const cloneEbook = async () => {
    if (!name) return alert('Please enter Name');
    if (!tempServerPath) return alert('Please enter Ebook Path');
    setIsLoading(true);
    try { 
      const res = await Store.Api.Ebook.cloneEbook({
        id: ebook.id,
      }, {
        name,
        tempServerPath,
      });
      if (res.data.success) {
        alert('Ebook cloned successfully');
        window.location.reload();
      } else {
        alert(res.data.error);
      }
    } catch (error) {}
    setIsLoading(false);
  }

  return (
    <Col round1 bgWhite shadow p2 width={300}>
      <Text bold mb2>Duplicate ebook: {ebook.name}</Text>
      <Text mb0>Name of the new ebook</Text>
      <Input
        value={name}
        mb1
        onChange={newName => {
          setName(newName);
          if (shoudAutoFillTempPath.current) {
            setTempServerPath(newName.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase());
          }
        }}
      />

      <Text mb0>Ebook Path</Text>
      <Text caption mb0>Your ebook will be available at: {productionHost()}/<Text bold>{tempServerPath || '{your-ebook-path}'}</Text></Text>
      <Input
        value={tempServerPath}
        mb1
        onChange={(newvalue) => {
          // convert to snake case and lower case
          setTempServerPath(newvalue.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase());
        }}
        inputProps={{
          onFocus: () => {
            shoudAutoFillTempPath.current = false;
          }
        }}
      />
      <Button
        isLoading={isLoading}
        text='Clone'
        solid
        width='100%'
        onPress={cloneEbook}
      />
    </Col>
  );
};

export default EbookCloneModal;
