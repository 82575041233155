import { Col, Row, Text, CMSLayout, useUIState, ShimmerLoading, TouchField, Button, EbookDepartment, SwapIconEffect, Grid, RatioCol, ImageUploadChange, modal, ImageWithDimensions, EbookLanguage } from 'components';
import React, { useState, useEffect, useRef } from 'react';
import { IScreen, TEbook, TPrintJob, TSite, TUser } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator, Image, ScrollView } from 'react-native';
import moment from 'moment';
import { SVG } from 'assets';
import { SimpleLineIcons, Foundation } from '@expo/vector-icons';
import { saveAs } from 'file-saver';
import { TimeHelper } from 'helpers';
import { Entypo, Feather, Ionicons, AntDesign, FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import FormatText from './EditBook.FormatText';
import { SaveModal, ListRevisionModal } from './EditBook.Revisions';
import { PagesModal } from './EditBook.Pages';
import * as Animatable from 'react-native-animatable';

import EditEbook from './EditEbook';

const EditEbookLanguage: IScreen = () => {
  const { route } = useNavFunc();
  // @ts-ignore
  const { langCode, id } = route.params || {};

  return (
    <EditEbook langCode={langCode} id={id} />
  )
}

EditEbookLanguage.routeInfo = {
  title: 'Edit Ebook',
  path: '/ebooks/:id/:langCode/edit',
};

export default EditEbookLanguage;