import React, { useState } from 'react';
import { Row, Col, Text, Input, modal, TouchField, Button } from 'components';
import { COLOR } from 'const';
import { ScrollView, useWindowDimensions } from 'react-native';
import { TEbook } from 'type';
import moment from 'moment';
import Store from 'store';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import 'react-quill/dist/quill.snow.css';

var Size = Quill.import('attributors/style/size');
Size.whitelist = ['14px', '16px', '18px', '20px', '22px', '24px', '26px', '30px', '32px'];
Quill.register(Size, true);

export const SaveModal = ({ onSave }) => {
  const [v, setV] = useState('');
  return (
    <Col round1 bgWhite shadow p2 width={300}>
      <Text mb1>Please describe your changes</Text>
      <Input mb1 value={v} onChange={setV} placeholder="" />

      <Row justifyContent={'flex-end'}>
        <TouchField
          height={30}
          ph2
          middle
          borderColor={COLOR.GREY}
          borderRadius={10}
          borderWidth={1}
          backgroundColor={COLOR.GREY}
          m0
          onPress={() => {
            if (!v) return alert('Please fill in the field');
            modal.hide();
            onSave(v);
          }}
        >
          <Row>
            <Text color={'white'}>Save</Text>
          </Row>
        </TouchField>
      </Row>
    </Col>
  )
}

export const HTMLQuillModal = ({ html, onUpdateHTML }: { html: string, onUpdateHTML: any }) => {
  const { width, height } = useWindowDimensions();
  const [tempHTML, setTempHTML] = useState(html);
  const apply = async (item) => {

  }
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      [{ 'size': ['14px', '16px', '18px', '20px', '22px', '24px', '26px', '30px', '32px'] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      [{ 'color': [] }, { 'background': [] }],  
      ['clean']
    ],
  };

  const formats = [
    'header', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'color', 'background'
  ];

  return (
    <Col round1 bgWhite shadow p2 width={width * 0.8} height={height * 0.8} onPress={() => {}} activeOpacity={1}>
      <Col flex1 mb2>
        <ReactQuill
          theme="snow" value={tempHTML} onChange={setTempHTML} style={{ height: 'calc( 100% - 50px )' }}
          modules={modules}
          formats={formats}
        />
      </Col>
      <Row justifyContent={'flex-end'} >
        <Button
          text="Cancel"
          outline
          ml1
          width={60} height={30} borderRadius={20}
          onPress={async () => {
            modal.hide();
          }}
          mr1
        />
        <Button
          text="Save"
          ml1
          width={60} height={30} borderRadius={20}
          onPress={async () => {
            modal.hide();
            onUpdateHTML(tempHTML);
          }}
        />
      </Row>
    </Col>
  )
}