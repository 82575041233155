import { Col, Text, CMSLayout } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';

const Home : IScreen = ( ) => {
  const { navigate } = useNavFunc();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    UserStore.onReady().then((u) => {
      if (!u) {
        navigate(SCREEN.Login);
      }
    });
  }, []);

  useEffect(() => {
    if (!isLoggedIn && !!user) {
      setIsLoggedIn(true);
    }
    if (!user && isLoggedIn) {
      setIsLoggedIn(false);
    }
  }, [user, isLoggedIn]);

  const userName = !user ? '' : `${user.firstName} ${user.lastName}`;

  return !isLoggedIn ? (
    <Col flex1 middle>
      <ActivityIndicator color="black" size="large" />
      <Text mt2>Please wait...</Text>
    </Col>
  ) : (
    <CMSLayout>
      <Col flex1 m2 p2 round1 bgWhite>
        <Text h4>Welcome, {userName}.</Text>
        <Text mt2 body1>Check out the <a href="https://codesigned-vn.gitbook.io/prism-present-global/" target='_blank' style={{ textDecoration: 'underline' }}>manual</a> to use this CMS.</Text>
      </Col >
    </CMSLayout>
  )
};

Home.routeInfo = {
  title: 'Prism Present',
  path: '/',
};

export default Home;