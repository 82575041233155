import React, { useEffect, useState } from 'react';
import { Row, Col, Text, Input, modal, TouchField, Button, ReorderList } from 'components';
import { COLOR } from 'const';
import { ScrollView, useWindowDimensions } from 'react-native';
import { TEbook } from 'type';
import moment from 'moment';
import Store from 'store';
import { Ionicons, FontAwesome, SimpleLineIcons, MaterialCommunityIcons } from '@expo/vector-icons';

export const SaveModal = ({ onSave }) => {
  const [v, setV] = useState('');

  return (
    <Col round1 bgWhite shadow p2 width={300}>
      <Text mb1>Please describe your changes</Text>
      <Input mb1 value={v} onChange={setV} placeholder="" />

      <Row justifyContent={'flex-end'}>
        <TouchField
          height={30}
          ph2
          middle
          borderColor={COLOR.GREY}
          borderRadius={10}
          borderWidth={1}
          backgroundColor={COLOR.GREY}
          m0
          onPress={() => {
            if (!v) return alert('Please fill in the field');
            modal.hide();
            onSave(v);
          }}
        >
          <Row>
            <Text color={'white'}>Save</Text>
          </Row>
        </TouchField>
      </Row>
    </Col>
  )
}

export const PagesModal = ({ ebook, jumpToPage, isForAssignPage, onAssignPage, langCode }: { ebook: TEbook, jumpToPage?: Function, isForAssignPage?: boolean, onAssignPage?: any, langCode: string }) => {
  const { width, height } = useWindowDimensions();
  const [message, setMessage] = useState('');
  const [pages, setPages] = useState<Array<{ url: string, id: string, name: string, pageTitle: string, index: number, pageId: string }>>([]);

  useEffect(() => {
    Store.Api.Ebook.getPages({ id: ebook.id, }, { langCode })
      .then(res => {
        console.log('res.data', res.data);
        if (res.data.data && Array.isArray(res.data.data)) {
          setPages(res.data.data.map((v, vi) => {
            const editUrl = (process.env.NODE_ENV !== 'development' ? 'https://ebooks.prismpresent.com/e' : 'http://localhost:3001/e') + ebook?.tempServerPath + v.fileName;
            return {
              url: editUrl,
              id: 'original-' + vi,
              name: 'Page ' + (vi + 1),
              index: vi,
              pageTitle: v.pageTitle,
              pageId: v.pageId,
            }
          }))
        }
      })
  }, []);

  const clonePage = (val, i) => {
    const newPages = [
      ...pages.slice(0, i + 1),
      {
        ...val,
        id: val.id + '_dup',
        name: val.name + ' duplicated',
      },
      ...pages.slice(i + 1, val.length),
    ];
    setPages(newPages);
  };

  return (
    <Col round1 bgWhite shadow p2 width={width * 0.8} height={height * 0.8}>
      <ScrollView>
        <ReorderList
          data={pages}
          onChange={setPages}
          renderItem={(val, i) => {
            return (
              <Col mb0>
                <Row backgroundColor={i % 2 === 0 ? 'rgba(0,0,0,0.05)' : 'rgba(0,0,0,0.1)'}>

                  <Col width={192} height={108}>
                    <iframe
                      src={val.url}
                      style={{
                        border: 'none', outline: 'none',
                        width: 1920, height: 1080,
                        transform: 'scale(0.1)',
                        transformOrigin: 'top left',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                      }}
                    />
                    <Col absoluteFill onPress={isForAssignPage ? undefined : () => { modal.hide(); jumpToPage(i); }} />
                  </Col>
                  {
                    isForAssignPage ? (
                      <>
                        <Col p1>
                          <Text mb0>{val.pageTitle}</Text>
                          <TouchField width={150} flexDirection={'row'} p1 onPress={() => { modal.hide(); onAssignPage && onAssignPage(val.pageTitle); }}>
                            <MaterialCommunityIcons name="page-next" size={16} color="black" />
                            <Text caption ml0>Choose this page</Text>
                          </TouchField>
                        </Col>
                        <Col flex1></Col>
                      </>
                    ) : (
                      <>
                        <Col p1>
                          <Text mb0>{val.pageTitle}</Text>
                          <TouchField width={150} flexDirection={'row'} p1 onPress={() => { modal.hide(); jumpToPage(i); }}>
                            <MaterialCommunityIcons name="page-next" size={16} color="black" />
                            <Text caption ml0>Jump to page</Text>
                          </TouchField>
                        </Col>
                        <Col flex1></Col>
                        <Col mr1>
                          <Row mb1 justifyContent={'flex-end'}>
                            <Text mr1>{val.name}</Text>
                            <Ionicons name="reorder-four" size={24} color="black" />
                          </Row>
                          <Row justifyContent={'flex-end'}>
                            <TouchField middle cirle onPress={() => clonePage(val, i)}>
                              <FontAwesome name="clone" size={12} color="black" />
                            </TouchField>
                            <TouchField middle cirle onPress={() => setPages(v => v.filter((item, itemIndex) => itemIndex !== i))}>
                              <SimpleLineIcons name="trash" size={12} color="black" />
                            </TouchField>
                          </Row>
                        </Col>
                      </>
                    )
                  }


                </Row>
              </Col>
            )
          }}
        />
        {isForAssignPage ? null : (
          <Row mt2 justifyContent={'flex-end'}>
            <Col width={300}>
              <Input height={30} value={message} onChange={setMessage} placeholder="Describe the update in short" />
            </Col>
            <Button
              text="Save"
              ml1
              width={60} height={30} borderRadius={20}
              onPress={async () => {
                if (!message) return alert('You need to describe the update you have made');
                const res = await Store.Api.Ebook.savePages({ id: ebook.id }, {
                  message,
                  pages: pages.map(v => ({ index: v.index })),
                });
                if (res.data.success) {
                  alert('The ebook has been saved succesfully');
                  window.location.reload();
                }
              }}
            />
          </Row>
        )}
        
      </ScrollView>
    </Col>
  )
}