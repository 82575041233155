import React, { useEffect, useRef, useState } from 'react';
import { Col, TouchField } from '../../index';
import UploadFile from './UploadFile';
import { Entypo } from '@expo/vector-icons';
import { Text, Row } from 'components';

const VideoUploadChange = ({ onChange, videoUrl, renderVideo, ...props } : any) => {
  const [isUploading, setIsUploading] = useState(false);
  const uploadRef = useRef<{ showDialog: Function }>(null);

  const onVideoUploaded = (urls) => {
    onChange(urls[0]);
  };

  return (
    <Col {...props}>
      {
        isUploading && (
          <Col
            minHeight={100}
            flex={1}
            middle
            center
          >
            <Text>
              Uploading...
            </Text>
          </Col>
        )
      }
      {!isUploading && renderVideo(videoUrl)}
      <UploadFile
        ref={uploadRef}
        setIsUploading={setIsUploading}
        onUploaded={onVideoUploaded}
        isVideoFormat
      />
      <TouchField
        absolute
        top={0}
        right={0}
        backgroundColor={"rgba(255,255,255,0.5)"}
        cirle middle
        onPress={() => {
          uploadRef?.current && uploadRef.current.showDialog();
        }}
      >
        <Entypo name="upload" size={15} color="black" />
      </TouchField>
    </Col>
  );
};

export default VideoUploadChange;
