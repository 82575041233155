import Request from '../Request.utils'


class TestAPI {
  helloWorld = async () => {
    const res = await Request.call('/api', 'GET', undefined, undefined, undefined, );
    return res;
  }
  testSendMail = async () => {
    const res = await Request.call('/api/test/send-email', 'POST', undefined, undefined, undefined, );
    return res;
  }
  testRaiserEdge = async () => {
    const res = await Request.call('/api/test/send-raiser-edge', 'POST', undefined, undefined, undefined, );
    return res;
  }
}
export default new TestAPI()