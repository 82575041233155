import React, { useEffect, useRef, useState } from 'react';
import { Col, Text } from 'components';
import { Image } from 'react-native';

const ImageWithDimensions = ({ url, ...props }) => {
  const [{ width, height }, setSize] = useState({ width: 1, height: 1 });
  const imgRef = useRef(null);

  const checkDimensions = ( ) => {
    // console.log('imgRef.current', imgRef.current);
    setTimeout(() => {
      if (!imgRef.current) return;
      const imgEl = imgRef.current.querySelector('img');
      // console.log('imgEl', imgEl);
      if (!imgEl) return;
      // console.log(imgEl.naturalWidth, imgEl.naturalHeight)
      setSize({
        width: imgEl.naturalWidth,
        height: imgEl.naturalHeight,
      }) 
    }, 500);
  }
  useEffect(() => {
    checkDimensions();
  }, [url]);

  return (
    <>
      <Image
        ref={imgRef}
        source={{ uri: url }}
        {...props}
        onLoad={() => {
          checkDimensions();
        }}
      />
      {Boolean(width > 1) && (
        <Col absolute bottom={0} left={0} padding={2} backgroundColor={'rgba(255,255,255,0.7)'}>
          <Text>{width} x {height}</Text>
        </Col>
      )}
    </>
  );
};

export default ImageWithDimensions;