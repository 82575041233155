import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Text, Select01 } from 'components';
import { IScreen, TEbook, TPrintJob, TSite, TUser } from 'type';
import Store from 'store';

const EbookDepartment = ({ ebook }: { ebook: TEbook }) => {
  const [dep, setDep] = useState<{ value: string, label: string }>();
  const [didSave, setDidSave] = useState(false);
  const [allOptions, setAllOptions] = useState<Array<{ value: string, label: string }>>([]);

  const informThisHasBeenSaved = () => {
    setDidSave(true);
    setTimeout(() => {
      setDidSave(false);
    }, 2000);
  }

  const UserStore = Store.useUserStore();
  const { user } = UserStore;

  useEffect(() => {
    if (ebook?.departmentId) {
      setDep({
        label: ebook.departmentTitle,
        value: ebook.departmentId,
      })
    }
  }, [ebook]);

  useEffect(() => {
    if (!ebook?.siteId) return;
    Store.Api.Site.getDepartments({
      id: ebook.siteId
    })
    .then(res => {
      if (res.data.data) {
        setAllOptions(
          (res.data.data || []).map((d) => ({ label: d.title, value: d.id }))
        )
      }
    })
  }, [ebook?.siteId]);

  const { departmentOptions, canEdit } = useMemo(() => {
    if (!user) return {
      departmentOptions: [],
      canEdit: false,
    }
    if (user.role === 'admin') return {
      departmentOptions: allOptions,
      canEdit: true,
    }
    if (user.role === 'client-admin') return {
      departmentOptions: allOptions,
      canEdit: true,
    }
    if (user.role === 'user') {
      const assignedDepartments = user.otherData?.departments || [];
      return {
        departmentOptions: allOptions.filter((d) => assignedDepartments.includes(d.value)),
        canEdit: true,
      }
    }
    return {
      departmentOptions: [],
      canEdit: false,
    }
  }, [user, allOptions]);

  const updateDepartmentToEbook = async (data) => {
    setDep(data);
    if (data && data?.value) {
      const res = await Store.Api.Ebook.updateDepartment({
        id: ebook.id,
      }, {
        departmentId: data.value,
        departmentTitle: data.label,
      });
      if (res.data.success) {
        informThisHasBeenSaved();
      }
    }
  }

  return canEdit && departmentOptions.length > 0 ? (
    <Row width={250}>
      <Col flex1>
        <Select01
          value={dep}
          onChange={updateDepartmentToEbook}
          backgroundColor={'white'}
          options={departmentOptions}
          placeholder="Select department"
        />
      </Col>
      {didSave && <Text color="green" caption mh1>Saved</Text>}
      <Text mh2> / </Text>
    </Row>
  ) : (
    null
  );
}

export default EbookDepartment;