import moment from 'moment';

class TimeHelper {
  
  wait(miliseconds) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined);
      }, miliseconds)
    });
  }

  formatDay(time) {
    return moment(time).format('DD/MM/YYYY');
  }

  format(time) {
    return moment(time).format('MM:hh:ss DD/MM');
  }
}

export default new TimeHelper();