import Request from '../Request.utils'


class AiAPI {
  summarize = async () => {
    const res = await Request.call('/api/summarize', 'POST', undefined, undefined, undefined, );
    return res;
  }
  copilot = async () => {
    const res = await Request.call('/api/copilot', 'POST', undefined, undefined, undefined, );
    return res;
  }
}
export default new AiAPI()