import React, { useEffect, useState, useRef } from 'react';
import { Col, Text, Row, TouchField, Input, Grid, modal } from 'components';
import { FontAwesome5, Entypo } from '@expo/vector-icons';
import { COLOR } from 'const';
import { SketchPicker } from 'react-color';
import { PagesModal } from './EditBook.Pages';
import { HTMLQuillModal } from './EditBook.QuillEditor';

interface Props {
  format: {
    bold: boolean,
    italic: boolean,
    underline: boolean,
    fontSize: number,
    color: string,
    hyperlink: string,
  },
  content: string,
  onSendCommand: any,
  pageJump: string,
  parentParagraphHTML: string,

  ebookData: any,
  sendEventToIframe: any,
}

const IconBox = ({ children, ...props }) => (
  <TouchField borderRadius={2} width={30} height={30} borderWidth={0.5} borderColor={'rgba(0,0,0,0.2)'} middle {...props}>
    {children}
  </TouchField>
);

const FontSizeBox = ({ size, isActive, ...props }) => {
  return (
    <IconBox {...props}>
      <Text bold={isActive}>{size}</Text>
    </IconBox>
  )
}

const INACTIVECOLOR = COLOR.GREY;
const ACTIVECOLOR = 'black';

const FormatText = ({ format, onSendCommand, content, pageJump, ebookData, sendEventToIframe, parentParagraphHTML } : Props) => {
  const [tempFontSize, setTempFontSize] = useState(format.fontSize);
  const [tempContent, setTempContent] = useState(content);
  const [tempLink, setTempLink] = useState(pageJump);
  const _timeoutRef = useRef<any>();
  const inputFontSizeRef = useRef<any>(null);
  const inputContentRef = useRef<any>(null);
  const inputLinkRef = useRef<any>(null);

  const onAssignPageJump = () => {
    modal.show(
      <PagesModal
        ebook={ebookData}
        isForAssignPage
        onAssignPage={(pageTitle) => {
          const pageId = pageTitle.replace(/ /g, '-');
          setTempLink(pageId);
          sendEventToIframe({ type: 'ASSIGN_PAGE_JUMP', payload: pageId });
        }}
      />
    )
  }

  return (
    <Col p2>
      <Text caption mb1>Formatting</Text>
      <Row mb1>
        <IconBox mr0
          onPress={() => onSendCommand('bold')}
        >
          <FontAwesome5 name="bold" size={12} color={ format.bold ? ACTIVECOLOR : INACTIVECOLOR } />
        </IconBox>
        <IconBox mr0
          onPress={() => onSendCommand('italic')}
        >
          <FontAwesome5 name="italic" size={12} color={ format.italic ? ACTIVECOLOR : INACTIVECOLOR } />
        </IconBox>
        {/* <IconBox
          onPress={() => onSendCommand('underline')}
        >
          <FontAwesome5 name="underline" size={12} color={ format.underline ? ACTIVECOLOR : INACTIVECOLOR } />
        </IconBox> */}
        <Col flex1 />
        <IconBox mr0
          onPress={() => onSendCommand('undo')}
        >
          <FontAwesome5 name="undo" size={12} color={ACTIVECOLOR} />
        </IconBox>
        <IconBox mr0
          onPress={() => onSendCommand('redo')}
        >
          <FontAwesome5 name="redo" size={12} color={ACTIVECOLOR} />
        </IconBox>
      </Row>
      <Text caption mb1>Page link</Text>
      <Col>
        <Input
          value={tempLink}
          onRef={ref => {
            inputLinkRef.current = ref;
          }}
          inputProps={{
            editable: false,
          }}
          onChange={(newVal) => {
            setTempContent(newVal);
            clearTimeout(_timeoutRef.current);
            _timeoutRef.current = setTimeout(() => {
              setTimeout(() => {
                inputLinkRef.current.focus();
              }, 500);
            }, 500);
          }}
          mb1
        />
        <Col middle absolute top={6} width={30} height={30} borderWidth={0.5} borderColor={'black'} borderRadius={15} backgroundColor={'rgba(255,255,255,1)'} right={6}
          onPress={onAssignPageJump}
        >
          <FontAwesome5 name="link" size={12} color="black" />
        </Col>
      </Col>

      <Row mb1 justifyContent={'space-between'}>
        <Text caption >Content</Text>
        {!!parentParagraphHTML && (
          <Col padding={2}
            onPress={() => {
              modal.show(
                <HTMLQuillModal
                  html={parentParagraphHTML}
                  onUpdateHTML={(newHTML) => {
                    sendEventToIframe({ type: 'UPDATE_SELECTION_PARENT_HTML', payload: newHTML });
                  }}
                />
              , {
                containerOnClick: () => {},
              })
            }}
          >
            <Text caption textDecorationLine={'underline'}>Whole Paragraph</Text>
          </Col>
        )}
      </Row>
      <Input
        value={tempContent}
        onRef={ref => {
          inputContentRef.current = ref;
        }}
        height={100}
        inputProps={{
          multiline: true,
          // @ts-ignore
          style: {
            overflow: 'auto',
          },
        }}
        onChange={(newVal) => {
          setTempContent(newVal);
          clearTimeout(_timeoutRef.current);
          _timeoutRef.current = setTimeout(() => {
            onSendCommand('content', newVal);
            setTimeout(() => {
              inputContentRef.current.focus();
            }, 500);
          }, 500);
        }}
        mb1
      />

      <Text caption mb1>Font size</Text>
      <Row style={{ flexWrap: 'wrap' }} marginHorizontal={-5}>
        {[12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40].map((val, i) => {
          return (
            <FontSizeBox
              m0
              size={val}
              key={'size-'+val}
              isActive={val === tempFontSize}
              onPress={() => {
                setTempFontSize(val);
                onSendCommand('fontSize', val + 'px');
              }}
            />
          )
        })}
      </Row>
      <Input
        onRef={ref => {
          inputFontSizeRef.current = ref;
        }}
        mt0
        style={{ height: 30, width: '100%' }}
        borderRadius={2}
        borderWidth={0.5} borderColor={'rgba(0,0,0,0.2)'}
        value={tempFontSize}
        onChange={(newVal) => {
          setTempFontSize(newVal);
          clearTimeout(_timeoutRef.current);
          _timeoutRef.current = setTimeout(() => {
            setTempFontSize(newVal);
            onSendCommand('fontSize', newVal + 'px');
            setTimeout(() => {
              inputFontSizeRef.current.focus();
            }, 500);
          }, 500);
        }}
      />

      <Col mt2>
        <SketchPicker
          width={180}
          color={format.color}
          onChangeComplete={(newColor) => {
            onSendCommand('foreColor', newColor.hex);
          }}
        />
      </Col>
    </Col>
  );
};

export default FormatText;
